.page {
    height: 100%;
    width: 100%;
}

.content {
    width: 100%;
    background-size: cover !important;
    float: left;
}

.content_mobile {
    width: 100%;
    float: left;
    background-color: #6db2d3;
}

.inner_content {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
    float:left;
}

.inner_content_mobile {
    min-height: unset;
}
