.content {
    width: 100%;
    overflow: hidden;
}

.description {
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 17px;
}

.description p {
    margin:0;
    padding: 0 0 10px 0;
}

