.content {
    width:100%;
    display: block;
    overflow: hidden;
    padding: 0 0 20px 0;
}

.filters {
    overflow: hidden;
}

.options_title {
    float:left;
    width: 100%;
    display: block;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 0 10px 0;
    margin: 0;
}

.other_options {
    overflow: hidden;
    width: 100%;
}
