.base_block {
    margin: 10px;
    border-radius: 12px;
}

.base_block_group {
    float:left;
    margin-bottom: 20px;
}

.group_outer {
    position: relative;
    float: left
}

.inner {
    float:left;
    width:100%;
    background-color: #ffffff;
    border-radius: 12px;
}

.width_1 {
    width: 241px;
}

.width_1_group {
    width: 261px;
}

.width_2 {
    width: 502px;
}

.width_2_group {
    width: 522px;
}

.width_3 {
    width: 763px;
}

.width_3_group {
    width: 783px;
}

.width_4 {
    width: 1024px;
}

.width_4_group {
    width: 1224px;
}

.height_1 {
    height: 297px;
}

.height_2 {
    height: 614px;
}

.height_3 {
    height: 931px;
}

.height_4 {
    height: 1248px;
}

.height_5 {
    height: 1565px;
}

.height_6 {
    height: 1882px;
}

.height_7 {
    height: 2199px;
}

.height_8 {
    height: 2516px;
}

.height_9 {
    height: 2833px;
}

.height_10 {
    height: 3150px;
}

.height_auto {
    overflow: hidden;
    height: auto;
}

.width_1_mobile, .width_2_mobile, .width_3_mobile, .width_4_mobile {
    width: calc(100% - 20px);
    /*height: 350px;*/
}

/*.width_2_mobile, .width_3_mobile, .width_4_mobile {*/
/*    height: 310px;*/
/*}*/

.height_1_mobile {
    height: 350px;
}

.height_2_mobile {
    height: 620px;
}

.height_3_mobile {
    height: 930px;
}

.height_4_mobile {
    height: 1240px;
}

.clickable:hover {
    cursor: pointer;
    background-color: #ebfcff;
}
