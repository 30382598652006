.selector_block {
    width: 25%;
    height: 520px;
    float:left;
}

.selector_block_mobile {
    width: 100%;
    height: 100%;
    float:left;
}

.selector_title {
    float:left;
    width: 100%;
    height: 50px;
    background-color: #11a8c2;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    color: #ffffff;
    font-size: 18px;
    line-height: 50px;
    padding-left: 20px;
    box-sizing: border-box;
}

.selector_content {
    overflow-y: auto;
    float:left;
    width: calc(100% - 20px);
    height: 490px;
    background-color: rgba(17, 168, 194, 0.36);
    border-radius: 0 0 4px 0;
    padding: 20px 0 10px 20px;

}

.selector_content_mobile {
    padding: 0 0 0 20px;
    height: calc(100% - 110px);
    border-radius: 0;
}

.filters_options {
    float: left;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.filter_close_handler {
    width:18px;
    min-height: 18px;
    float:right;
    cursor: pointer;
    margin: 15px 20px 0 0;
}

.filter_section {
    float:left;
}

.subtitles {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    display: block;
    box-sizing: border-box;
    float: left;
    width: 100%;
    background-color: #50B9CC;
    font-family: CircularStd, sans-serif;
    color: #000;
    font-size: 18px;
    margin-left:0;
    margin-top: 40px;
    padding: 10px 0 10px 20px;
    min-height: 25px;
    line-height: 25px;
    cursor: default;
}

.subtitles_first {
    margin-top: 10px;
}

.title {
    float:left;
    width: 100%;
    display: block;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 0 10px 0;
    margin: 0;
}

.selection_type_button {
    margin: 2px 5px 2px 0;
    padding: 0 10px 0 10px;
    width: auto;
    height: 30px;
    float: left;
    border-radius: 4px;
    background-color: rgba(132,133,134,1);
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.selection_type_button:hover, .selection_type_button:active, .selection_type_button_active {
    background-color: #11a8c2;
}

.other_filter {
    float:left;
    width: 100%;
    padding: 12px 0 10px 0;
}
