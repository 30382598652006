.button {
    display: block;
    padding: 0 10px 0 10px;
    height:55px;
    line-height: 55px;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.button:hover, .button:active {
    cursor: pointer;
}

.button img {
    padding: 13px 2px 0 2px;
    width: auto;
    height: 30px;
    float:left;
}

.button span {
    float:left;
}

.link {
    color: #000000;
    padding: 0 10px 0 0;
}

.link {
    color: #000000;
    text-decoration: underline;
}

.link span:hover {
    color: #000000;
    text-decoration: underline;
}

.button_gray {
    color: #000000;
}

.button_gray_desktop:hover, .button_gray_desktop:active {
    cursor: pointer;
    background-color: #c9cacb;
    color: #000000;
    text-decoration: none;
}

