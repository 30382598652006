.intro_block {
    z-index: 888;
    float:left;
    height: 100%;
    min-height: calc(100vh - 80px);
    position: fixed;
    background-color: #e9f4f8;
    -webkit-transition: margin-left 700ms;
    -moz-transition: margin-left 700ms;
    -ms-transition: margin-left 700ms;
    -o-transition: margin-left 700ms;
    transition: margin-left 700ms;
}

.intro_block_mobile {
    width:100%;
    height: 100%;
    min-height: calc(100vh - 60px);
    /*background-color: rgba(255, 255, 255, 1);*/
}

.intro_block_pc {
    width:400px;
}

.open {
    margin-left: 0;
}

.close {
    margin-left: -358px
}

.header {
    width:100%;
    height: 42px;
    padding-bottom: 0;
    margin-left: 0;
    -webkit-transition: margin-left 700ms;
    -moz-transition: margin-left 700ms;
    -ms-transition: margin-left 700ms;
    -o-transition: margin-left 700ms;
    transition: margin-left 700ms;
}

.close_mobile {
    /*margin-top: -30px;*/
    margin-left: -100%
}

.close_mobile .header {
    margin-left: 42px;
}

.toggle_button {
    float:right;
    width: 42px;
    height: 42px;
    background-color: #11a8c2;
    cursor: pointer;
}

.toggle_button_desktop:hover {
    background-color: #116076;
}

.toggle_button img {
    width: 30px;
    height: 30px;
    margin: 4px 0 0 4px;
    -webkit-transition: transform 700ms;
    -moz-transition: transform 700ms;
    -ms-transition: transform 700ms;
    -o-transition: transform 700ms;
    transition: transform 700ms;
}

.toggle_button_closed img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.content {
    z-index: 900;
    float:left;
    width: 100%;
    padding: 20px 30px 20px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.content_inner {
    width: 334px;
}

.content_inner_mobile {
    width: 100%;
}

.socials {
    z-index: 900;
    float:left;
    width: 100%;
    padding: 20px 0 0 0;
}
