.blocks {
    -webkit-transition: margin-left 700ms;
    -moz-transition: margin-left 700ms;
    -ms-transition: margin-left 700ms;
    -o-transition: margin-left 700ms;
    transition: margin-left 700ms;
    width: calc(100% - 420px);
    padding: 20px 0 20px 0;
    /*float:left;*/
}

.blocks_mobile {
    padding: 0 0 20px 0;
}

.sidebar_open {
    margin-left: 420px;
}

.sidebar_close {
    margin-left: 62px;
}

.sidebar_close_ready {
    width: calc(100% - 62px);
}

.date_info_mobile {
    width: 95%;
    padding: 0 2.5% 0 2.5%;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    text-align: right;
}

@media only screen and (max-width: 480px) {
    .blocks {
        width: 100%;
    }

    .sidebar_open {
        margin-left: 0;
        position: fixed;
    }

    .sidebar_close {
        margin-left: 0;
    }

    .sidebar_close_ready {
        width: 100%;
    }
}

.filters {
    width: calc(100% - 10px);
    padding: 0 0 10px 10px;
    /*float:left;*/
}

.filters_mobile {
    padding: 50px 0 30px 10px;
}

.filter_title {
    font-family: CircularStd, sans-serif;
    font-size: 18px;
    float: left;
    width: 100%;
}

.filter_buttons {
    width: 100%;
    float:left;
    margin: 10px 0 30px 0;

}

.blocks_container {
    float:left;
    width: 100%;
    margin-bottom: 80px;
}

.footer {
    float: left;
    width: 80%;
    margin: 0 10% 40px 10%;
    font-family: CircularStd, sans-serif;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

