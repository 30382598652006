.page {
    width:70%;
    max-width: 1200px;
    margin: 40px auto 40px auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 12px;
    overflow: hidden;
}

h1, .title {
    width: 100%;
    display: block;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 21px;
    padding: 0 0 5px 0;
    margin: 0;
}

.logos_title {
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 17px;
}

.content_text_block {
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 40px;
    float:left;
}

.content_text_block p {
    margin: 0 0 17px 0;
}

.logos {
    padding: 20px 0 0 0;
    width: 100%;
    float:left;
}

.single_logo {
    width: 170px;
    height: 60px;
    margin: 15px 40px 15px 0;
}

.single_logo img {
    width: 100%;
}

.footer {
    margin-top: 80px;
    float:left;
}

.footer_socials {
    display: inline;
    margin-left: 20px;
    padding-top: 16px;
    float:left;
}

@media only screen and (max-width: 680px) {
    .page {
        width:96%;
        margin: 20px 2% 20px 2%;
    }

    .footer {
        margin-top: 20px;
    }

    .footer_socials {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-top: 40px;
    }
}
