.header {
    width: 100%;
    height: 80px;
    position: fixed;
    background-color: #ffffff;
    z-index: 888;
}

.header_fill {
    width: 100%;
    height: 80px;
}

.logo {
    width: auto;
    height: 60px;
    padding: 10px 10px 10px 20px;
    float: left;
}

.title {
    float: left;
    padding: 8px 0 0 10px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 23px;
}

.header_content_right {
    width: auto;
    float: right;
}

.language_selector {
    float: left;
    margin: 25px 50px 0 0;
}

.language_selector_mobile {
    margin: 15px 5% 0 0 !important;
}

.date_info {
    width: 250px;
    float: left;
    padding: 25px 60px 0 0;
    font-size: 19px;
    text-align: right;
}

@media only screen and (max-width: 850px) {
    .header {
        height: 60px;
    }

    .header_fill {
        width: 100%;
        height: 60px;
    }

    .logo {
        height: auto;
        width:25%;
        max-height: 30px;
        float:left;
        padding: 10px 0.5% 10px 0.5%;
    }

    .title {
        padding: 8px 0 0 0.5%;
        font-size: 14px;
        width: 35%;
    }

    .header_content_right {
        width:38%;
    }


    .language_selector {
        float: right;
        margin: 5px 2.5% 0 0;
    }

    .date_info {
        float: right;
        width: 100%;
        padding: 5px 2.5% 0 0;
        font-size: 12px;
    }
}
