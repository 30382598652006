.filter_group {
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 8px;
}

.filter_title {
    width: 100%;
    display: block;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding: 0 0 10px 0;
    margin: 0;
}
