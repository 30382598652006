.block {
    width: auto;
    height: auto;
}

/*@media only screen and (max-width: 552px) {*/
.single_block_mobile {
    width: 50%;
}

.double_block_mobile {
    width: 100%;
}
/*}*/
