.selector {
    position: relative;
    width:100px;
    height:29px;
}

.selector_background {
    margin: 2px 0 2px 0;
    width:100px;
    height: 25px;
    position: absolute;
    z-index: 1;
    float: left;
    border-radius: 4px;
    background-color: rgba(132,133,134,.2);
}

.selector_current {
    height: 29px;
    width: 50px;
    background-color: #11a8c2;
    position: absolute;
    z-index: 5;
    float: left;
    border-radius: 4px;
    -webkit-transition:  .4s;
    -moz-transition:  .4s;
    -ms-transition:  .4s;
    -o-transition:  .4s;
    transition:  .4s;
}

.selector_en {
    left: 50px;
}

.selector_en_changed {
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    transform: translateX(-50px);
}

.selector_nl {
    left: 0;
}

.selector_nl_changed {
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px);
}

.language_overlay {
    width:100px;
    margin: 2px 0 2px 0;
    height: 25px;
    position: absolute;
    z-index: 10;
    float: left;
}

.language_label {
    width:50px;
    height: 25px;
    color: #282c34;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    z-index: 10;
    float: left;
    -webkit-transition: color .4s;
    -moz-transition: color .4s;
    -ms-transition: color .4s;
    -o-transition: color .4s;
    transition: color .4s;
    cursor: pointer;
}

.language_label_active {
    color: #ffffff;
    cursor: default;
}
