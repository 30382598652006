.button {
    width: auto;
    height: auto;
    margin: 0 10px 10px 0;
    padding: 5px;
    border-radius: 12px;
    line-height: 30px;
    background-color: #ffffff;
    color: #282c34;
    font-family: CircularStd, sans-serif;
    font-size: 16px;
    float: left;
    cursor: pointer;
}

.button_small {
    width: auto;
    height: auto;
    margin: 0 10px 10px 0;
    padding: 5px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #282c34;
    font-family: CircularStd, sans-serif;
    font-size: 14px;
    float: left;
    cursor: pointer;
}

.button_gray {
    background-color: rgba(132,133,134,0.2);
}

.button_mobile {
    font-size: 14px;
}

.button_desktop:hover, .button_desktop:active, .active, .active:hover, .active:active {
    background-color: #11a8c2;
    color: #ffffff;
}

.active, .active:hover, .active:active {
    cursor: default;
}

.disabled, .disabled:hover, .disabled:active {
    background-color: #f6f6f6;
    color: #acacac;
    cursor: not-allowed;
}

.disabled_active, .disabled_active:hover, .disabled_active:active {
    background-color: #95afbd;
    color: #f2f2f2;
    cursor: not-allowed;
}

.button img {
    float:left;
    width:auto;
    height: 30px;
}

.button span {
    display: block;
    float: left;
    padding: 0 7px 0 7px;
}
