.block {
    width: calc(100% - 20px);
    height: 40px;
    padding: 10px;
    float: left;
}

.icon  {
    float:left;
    padding-right: 10px;
}

.block_mobile .icon  {
    padding-right: 5px;
}

.icon img {
    width: 38px;
    min-height: 38px;
}

.title {
    padding-top: 8px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 19px;
    line-height: 1;
}

.title_with_info_icon {
    width: calc(100% - 75px);
    float:left;
}

.block_mobile .title {
    padding-top: 12px;
    font-size: 16px;
}

.info {
    float:right;
}

.info_icon {
    z-index: 777;
    position: absolute;
    top: 20px;
    right:20px;
    border: 1px solid #11a8c2;
    border-radius: 20px;
    color: #11a8c2;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 17px;
    text-align: center;
}

.info_icon:hover {
    color: #116076;
    border-color: #116076;
}

.info_icon:hover {
    cursor: pointer;
}

.info_box {
    position: absolute;
    top:35px;
    right:35px;
    margin: 0 auto 0 auto;
    width: calc(100% - 75px);
    max-width: 285px;
    background-color: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    z-index: 777;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 12px;
}

@media only screen and (max-width: 552px) {
    .info_box_mobile {
        right: auto;
    }
    .info_box_double_width_mobile {
        right: calc(1% + 30px);
    }
    .title_long  {
        padding: 10px 0 0 0;
        font-size: 14px;
    }
}


