.content {
    width:100%;
    float:left;
    height: auto;
}

.line_top {
    padding-top: 10px;
    border-top: 2px solid #b5b5b5;
}

.title {
    float:left;
    display: block;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 19px;
    padding: 0 0 10px 0;
    margin: 0;
    line-height: 30px;
}

.content:hover .title, .content:active .title {
    color: #15AAC4;
    text-decoration: none;
}

.content:hover, .content:active {
    cursor: pointer;
}

.icon {
    width: auto;
    height: 30px;
    float:left;
    margin-right: 10px;
}

.toggle_icon {
    width: auto;
    margin: 7px 0 10px 0;
    height: 18px;
    float:right;
}
