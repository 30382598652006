.socials span {
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 16px;
}

.socials img {
    width: 24px;
    height: auto;
    min-height: 24px;
    margin: 0 0 -7px 10px;
}

