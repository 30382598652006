.button {
    color: #95afbd;
    font-family: CircularStd, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
    float: left;
    padding: 25px 0 5px 0;
    cursor: not-allowed;
}

.clickable {
    cursor: pointer;
    color: #000000;
}

.clickable:hover, .clickable:active {
    color: #15AAC4;
    text-decoration: none;
}

.button span {
    display: block;
    float: left;
    padding-left: 10px;
}

.icon {
    height: 15px;
    width: auto;
    float: left;
}
