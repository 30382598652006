.Modal {
    position: absolute;
    top: calc(50% - 300px);
    left: calc(50% - 525px);
    right: auto;
    bottom: auto;
    width:1050px;
    height:600px;
    background: #ffffff;
    border-radius: 4px;
    outline: none;
}

.Overlay {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(53, 53, 53, 0.7);
}
