.block {
    width: 100%;
    height: 80px;
    float: left;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
}

.block_mobile {
    height: auto;
    border-radius: 0;
}

.icon  {
    float:left;
    padding: 15px 10px 0 10px;
}

.icon_mobile {
    padding: 0 10px 0 10px;
}

.icon img {
    width: 50px;
    min-height: 50px;
}

.title {
    float: left;
    padding: 28px 0 0 0;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 1;
}

.title_mobile {
    padding: 15px 0 10px 0;
    max-width: 160px;
}

.button_selector {
    padding: 0 12px 0 12px;
    height:50px;
    line-height: 50px;
    float: right;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 16px;
    background-color: #11a8c2;
    color: #ffffff;
}

.button_selector img {
    padding: 17px 10px 0 0;
    width: auto;
    height: 15px;
    float:left;
}

.button_selector:hover {
    cursor: pointer;
    background-color: #11687d;
}

.description {
    float: left;
    /*width: 100%;*/
    padding: 15px 10px 15px 15px;
    box-sizing: border-box;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
}

.description_text {
    width: calc(100% - 42px);
    float:left;
}

.description_desktop {
    max-width: 550px;
    padding: 23px 10px 0 30px;
}

.info_icon {
    z-index: 777;
    float:right;
    margin: -10px 0 0 0;
    border: 1px solid #11a8c2;
    border-radius: 20px;
    color: #11a8c2;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 17px;
    text-align: center;
}

.info_icon_mobile {
    float: right;
    margin: -10px 0 0 0;
}

.info_icon:hover {
    color: #116076;
    border-color: #116076;
    cursor: pointer;
}

.info_box {
    position: absolute;
    width: 350px;
    background-color: #ffffff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    z-index: 777;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 12px;
}

.info_box_mobile {
    width: 200px;
}

.exit_icon {
    width:18px;
    min-height: 18px;
    float:right;
    cursor: pointer;
    margin: 12px;
}
