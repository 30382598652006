.block_footer {
    min-height: 12px;
    margin: 0 0 10px 10px;
    padding: 9px 10px 12px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 30px);
    border-radius: 0 0 12px 12px;
    font-family: CircularStd, sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
}

.block_footer div {
    display: inline;
}

.icon_arrow {
    width:22px;
    height: 22px;
    padding-right: 5px;
    margin:-3px 0 -7px 0;
}

.block_footer span {
    display: inline;
    margin-bottom: -10px;
}

.bg_positive {
    background-color: rgba(0, 178, 44, 0.2);
    color: #00b22c;
}

.bg_neutral {
    background-color: rgba(132, 133, 134, 0.2);
    color: #848586;
}

.bg_negative {
    background-color: rgba(234, 24, 90, 0.2);
    color: #ea185a;
}
