.page {
    width:100%;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
}

.buttons {
    width: 100%;
    float: left;
    background-color: rgba(255, 255, 255, 1);
}

.button_back {
    padding: 0 10px 0 10px;
    height:55px;
    line-height: 55px;
    float:left;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 16px;
    background-color: #ffffff;
}

.button_back img {
    padding: 13px 2px 0 0;
    width: auto;
    height: 30px;
    float:left;
}

.button_back:hover {
    cursor: pointer;
    background-color: #c9cacb;
}

.filter_block {
    z-index: 888;
    float:left;
    height: 100%;
    position: fixed;
    width:70%;
    margin-left: 30%;
    background-color: #ffffff;
    -webkit-transition: margin-left 700ms;
    -moz-transition: margin-left 700ms;
    -ms-transition: margin-left 700ms;
    -o-transition: margin-left 700ms;
    transition: margin-left 700ms;
}

.open {
    margin-left: 30%;
}

.close {
    margin-left: 100%;
}
