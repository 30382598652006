.message_box {
    position: absolute;
    border: 2px solid #ea185a;
    border-radius: 5px;
    background-color: #fad7e0;
    width: calc(75% - 40px);
    margin-top: -60px;
    font-family: CircularStd, sans-serif;
    font-size: 15px;
    float:left;
    z-index: 999;
    box-sizing: border-box;
    padding: 20px;
}
