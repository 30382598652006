.filter_option {
    float: left;
    width: calc(100% - 24px);
    font-family: CircularStd, sans-serif;
    font-size: 14px;
    margin-left: 24px;
    padding: 3px 0 3px 0;
    min-height: 25px;
    line-height: 25px;
    cursor: default;
}

.filter_option span {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    border: 2px solid #221c1c;
    border-radius: 10px;
    margin: 5px 10px 0 -24px;
}

.special_option {
    color: #b0b1b2;
}

.selectable:hover {
    cursor: pointer;
}

.selectable:hover span {
    border-color: #221c1c;
    background-color: #221c1c;
}

.active span {
    border-color: #15aac4;
    background-color: #15aac4;
}

.selected span {
    border-color: #221c1c;
    background-color: #221c1c;
}
