.graph_block {
    width: calc(75% - 40px);
    height: 470px;
    background: #ebebeb;
    float:left;
    padding: 50px 20px 0 20px;
    border-radius: 0 0 0 4px;
}

.graph_block_mobile {
    width: 100%;
    height: 350px;
    padding: 20px 0 20px 0;
    box-sizing: border-box;
    border-radius: 0;
}
