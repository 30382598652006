.graph {
    z-index: 12;
}

/*.graph_box {*/
/*    position: absolute;*/
/*    top: 75px;*/
/*    width: calc(100% - 55px);*/
/*    padding: 0 20px 0 15px;*/
/*    font-family: CircularStd, sans-serif;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*}*/

.graph_box {
    width: calc(100% - 55px);
    float:left;
    padding: 0 20px 20px 15px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 14px;
}

.graph_box_in_modal {
    padding: 0 20px 0 15px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 14px;
}

@media only screen and (max-width: 552px) {
    .graph_box_mobile {
        width: calc(100% - 35px - 2%);
    }
}
