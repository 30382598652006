.percentage {
    width: calc(100% - 30px);
    float:left;
    padding: 10px 15px 4px 15px;
    text-align: left;
    font-family: CircularStd, sans-serif;
    font-weight: bold;
    font-size: 44px;
    line-height: 1;
}
