.description {
    width: calc(100% - 30px);
    text-align: left;
    float:left;
    padding: 0 15px 10px 15px;
    font-family: CircularStd, sans-serif;
    font-weight: normal;
    font-size: 17px;
}

.description_mobile {
    font-size: 15px;
}
